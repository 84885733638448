<template>
    <section class="work-order-management">
        <div class="top-bar bg-white" style="display:flex;flex-wrap: wrap;">
            <!-- 2024/04/19 张晓瑜工单管理列表 -->
            <div style="display:flex; width:300px;align-items: center;margin-bottom:10px">
                <div>工单编号</div>
                <el-input v-model="formSearch.wordNum" placeholder="请输入工单编号" style="margin: 0 7px; width: 200px" size="small"/>
            </div>
            <div style="display:flex; width:300px;align-items: center;margin-bottom:10px">
                <div>处理状态</div>
                <el-select v-model="formSearch.state" size="small" placeholder="全部" style="margin: 0 7px;; width: 200px">
                    <el-option :value="-1" label="全部 "/>
                    <el-option :value="0" label="待发起 "/>
                    <el-option :value="1" label="待资产部经理审核 "/>
                    <el-option :value="2" label="待财务部审核"/>
                    <el-option :value="3" label="待管理层审核"/>
                    <el-option :value="4" label="已完成"/>
                </el-select>
            </div>
            <div style="display:flex; width:300px;align-items: center;margin-bottom:10px">
                <div>租户名字</div>
                <el-input v-model="formSearch.lesseeName" placeholder="请输入租户名字" style="margin: 0 7px; width: 200px" size="small"/>
            </div>
            <div style="display:flex; width:300px;align-items: center;margin-bottom:10px">
                <div>房源地址</div>
                <el-input v-model="formSearch.name" placeholder="请输入房间地址" style="margin: 0 7px; width: 220px" size="small"/>
            </div>
            <div style="display:flex; width:420px;align-items: center;margin-bottom:10px">
                <div style="width:80px">申请时间</div>
                <el-date-picker v-model="StartAndEndDATE" type="daterange" range-separator="至" start-placeholder="开始日期"
                            style="margin: 0 7px;" end-placeholder="结束日期" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd"/>
            </div>  
            <div>
                <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
            </div>
            <div>
                <el-button  @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>
            </div>
            <!-- <el-button type="primary" size="small" icon="el-icon-download" @click="wordExport">导出</el-button> -->
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getWordListTableData" :columns="tableColumn" :query="formSearch"
                   tooltip-effect="light" :height="790" :row-style="rowStyle" :cell-style="cellStyle">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <div class="table-tools" v-if="row.wordType==='换房'">
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'queren')"
                                 v-if="row.state===2 && getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')">确认</span>
                            <span class="table-btn"   @click="openDialog('dialogHuanfang',row,'chakan')"
                                  v-if="row.state===2 && !getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')">查看</span>
                            <span class="table-btn" @click="openDialog('dialogHuanfang',row,'xinqian')"
                                  v-if="row.state===3 && getButtonMenu('WORKORDER_XINQIAN_BTUUON') ">新签</span>
                            <span class="table-btn"  @click="openDialog('dialogHuanfang',row,'chakan')"
                                  v-if="row.state===3 && !getButtonMenu('WORKORDER_XINQIAN_BTUUON')">查看</span>
                            <span class="table-btn"  @click="openDialog('dialogHuanfang',row,'chakan')"
                                  v-if="row.state===6 " >查看</span>
                        </div>
                        <div class="table-tools" v-if="row.wordType==='退房'">
                            <span class="table-btn"  @click="openDialog('dialogHandle',row,)"
                                v-if="row.state===0 "
                                >发起</span>
                            <span class="table-btn"  @click="openDialog('dialogHandle',row,)"
                                v-if="row.state===1 && getButtonMenu('Asset_department_review')"
                                 >审核</span>
                            <span class="table-btn"  @click="openDialog('dialogHandle',row,)"
                                v-if="row.state===2 && getButtonMenu('Reviewed_by_the_finance_department')"
                                 >审核</span>
                            <span class="table-btn"  @click="openDialog('dialogHandle',row,)"
                                v-if="row.state===3 && getButtonMenu('Management_review')"
                                 >审核</span>
                            <span class="table-btn" @click="openDialog('dialogHuikuan',row,)"
                                v-if="row.state===4"
                                >查看</span>
                        </div>
                        <!-- <span class="table-btn" v-if="row.state===2 && row.wordType==='退房' && getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')" @click="openDialog('dialogHandle',row,'chuli')">确认</span>
                        <span class="table-btn" v-if="row.state===2 && row.wordType==='退房' && !getButtonMenu('WORKORDER_CHECKOUT_CONFIRM_BUTTON')" @click="openDialog('dialogHuikuan',row,'chakan')">查看</span>
                        <span class="table-btn" v-if="row.state===3 && row.wordType==='退房' && getButtonMenu('WORKORDER_CHECKOUT_HANDLE_BUTTON')" @click="openDialog('dialogHandle',row,'hukuan')">处理</span>
                        <span class="table-btn" v-if="row.state===3 && row.wordType==='退房' && !getButtonMenu('WORKORDER_CHECKOUT_HANDLE_BUTTON')" @click="openDialog('dialogHuikuan',row,'chakan')">查看</span>
                        <span class="table-btn" v-if="row.state===6 && row.wordType==='退房'" @click="openDialog('dialogHuikuan',row,'chakan')">查看</span> -->   
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <dialog-check-out ref="dialogCheckOut" @handleSearch="handleSearch"/>

        <dialog-huikuan ref="dialogHuikuan" @handleSearch="handleSearch"/>
        <!-- 换房工单 -->
        <dialog-huanfang ref="dialogHuanfang" @handleSearch="handleSearch"/>
        <!-- 退房工单 -->
        <dialog-handle ref="dialogHandle" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import { tableWorkOrderColumn } from "./data";
    import { getWordList, wordExport} from "@/api/work-order-management";
    import { timeFormat } from "@custom/index";
    import { downloadByData } from "@/utils/hooks/download";
    import {buttonCookies} from "@storage/cookies";
    export default {
        name: "work-order-management",
        data() {
            return {
                tableColumn: tableWorkOrderColumn(this),
                formSearch: {
                    status: null,
                    startDate:'',
                    endDate:''
                },
                StartAndEndDATE:[],
                state: null,
            };
        },
        mounted() {

        },
        components:{
            dialogCheckOut: () => import("@/views/rental-management/house-resource/components/part/components/dialog-check-out"),
            dialogHuikuan: ()=>import("./components/dialog-huikuan"),
            dialogHuanfang: ()=>import("./components/dialog-huanfang"),
            dialogHandle: ()=>import("./components/dialog-handle")
        },
        methods: {
            getWordListTableData(params) {
                return getWordList(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData(true);
            },
            handleReset() {
                this.formSearch = {status: null,};
                this.StartAndEndDATE = [];
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            openDialog(openName = "", data,type) {
                this.$refs[openName].openDialog(data,type);
            },
            wordExport(){
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {formSearch}=this;
                let paramsData={...formSearch}
                wordExport(paramsData).then(res=>{
                    downloadByData({data: res, filename: `工单管理${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).catch(err => {loading.close();});
            },
            rowStyle({row, rowIndex}){
                let {state,wordType} = row;
                console.log('row',row);
                if(state===2) return {backgroundColor:'#FFF5F5'};
                if(state===6) return {backgroundColor:'#E5EFFF'};
            },
            cellStyle({row, column, rowIndex, columnIndex}){
                let {state} = row;
                if (state === 2 && columnIndex === 1) return {color: '#FF3718'};
                if (state === 3 && columnIndex === 1) return {color: '#FF3718'};
                if (state === 6 && columnIndex === 1) return {color: '#1890FF'};
            },
            getButtonMenu(menu){
                return JSON.parse(buttonCookies.get()).map(item=>item.url).some(v => v === menu);
            },
        },
        watch:{
            StartAndEndDATE(value) {
            if (value && value.length !== 0) {
                let [startDate, endDate] = value;
                this.formSearch.startDate = startDate;
                this.formSearch.endDate = endDate;
            } else {
                this.formSearch.startDate = null;
                this.formSearch.endDate = null;
            }
            },
            state(value) {
                if(value) this.formSearch.state = value;
                else this.formSearch.state = null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .work-order-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
            }
        }
    }
</style>